@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 5.2rem;
    height: 5.2rem;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(color('cream'), 0.1);
}

.facebook {
    width: 2.25rem;
    height: 2.17rem;
}

.instagram {
    width: 2.25rem;
    height: 2.25rem;
}

.tiktok {
    width: 1.65rem;
    height: 1.84rem;
}

.youtube {
    width: 2.45rem;
    height: 1.66rem;
}

.pinterest {
    width: 2.25rem;
    height: 2.25rem;
}

@include xxLarge {
    .root {
        width: 5.7rem;
        height: 5.7rem;
    }

    .facebook {
        width: 29rem;
        height: 2.79rem;
    }

    .instagram {
        width: 2.62rem;
        height: 2.62rem;
    }

    .tiktok {
        width: 2.3rem;
        height: 2.6rem;
    }

    .youtube {
        width: 2.86rem;
        height: 1.94rem;
    }

    .pinterest {
        width: 2.62rem;
        height: 2.62rem;
    }
}
